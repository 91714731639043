import React, {useEffect} from 'react'
import { graphql, Link } from 'gatsby'
import styled from '@emotion/styled'
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import Prism from "prismjs"
import { Disqus } from 'gatsby-plugin-disqus'

import Layout from '../components/layout'
import Sidebar from '../components/sidebar'
import SEO from '../components/seo'

const Til = styled.div`
  grid-column: 6 / span 6;
  grid-row: 1 / 4;
  max-width: 100%;
  @media screen and (max-width: 767px){
	grid-column: 1 / span 4;
  }
  @media screen and (min-width: 768px) and (max-width: 1023px){
	grid-column: 2 / span 6;
  }
  figure{
	text-align: center;
	border-radius: 3px;
	figcaption{
	  font-size: 0.8125em;
	  padding: 0.8rem;
	  font-family: var(--f-primary);
	  color: var(--c-grey);
	  font-style: italic;
	}
  }
  h6{
	text-transform: uppercase;
  }
`
const SidebarCol = styled.div`
  grid-column: 2 / span 3;
  @media screen and  (max-width: 1023px){
		display: none;
	}
`
const TilTitle = styled.h1`
    font-size: 2rem;
    margin: 0.5rem 0 2rem;
		color:black;
			text-decoration: none;
			transition: 300ms;
    }
    @media screen and (max-width: 767px){
        font-size: 1.5rem;
    }       
	`
const TilDate = styled.h6`
	font-size: 0.75em;
	font-weight: normal;
	color: var(--c-grey);
	margin: 0;
`
const Content = styled.div`
  line-height: 1.5;
  color: rgba(0,0,0, 0.8);
  font-family: var(--f-secondary);
  font-size: 1.125em;
  a{
	color: var(--c-tertiary);
		&:hover{
			text-decoration: none;
		}
  }
  blockquote{
		background: rgba(0,0,0,0.01);
		border-left: 3px solid var(--c-primary);
		margin: 0 0 1rem;
		padding: 1.5rem;
  }
  img,
  figure{
		max-width: 100%;
		height: auto;
  }
	code{
		&:after{
			content: "";
		}
	}
`
const Back = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  button{
	display: inline-flex;
	border: none;
	height: 2.5rem;
	padding-left: 2rem;
	padding-right: 2rem;
	align-items: center;
	background: var(--c-secondary);
	border-radius: 2.5rem;
	color: var(--c-primary);
	text-decoration: none;
	font-weight: 500;
  }
`
const shortcodes = { Link } 

const Project = ({ data: { mdx, site } }) => {

	useEffect(() => {
	  Prism.highlightAll();
	})

	const siteData = site;

	let disqusConfig = {
		url: `${siteData.siteMetadata.siteUrl+mdx.frontmatter.path}`,
		identifier: mdx.id,
		title: mdx.frontmatter.title,
	}


	return (
	  <Layout>
		<SEO />
		<div className="container">
			<SidebarCol><Sidebar headingText="I am a stupid person" /></SidebarCol>
			<Til>
				<MDXProvider components={shortcodes}>
					<TilDate>{mdx.frontmatter.date}</TilDate>
					<TilTitle>{mdx.frontmatter.title}</TilTitle>
					<Content>
						<MDXRenderer>
							{mdx.body}
						</MDXRenderer>
					</Content>
				</MDXProvider>

				<Disqus config={disqusConfig} style={{ marginTop: '4rem' }} />
			</Til>
		</div>
	  </Layout>
	)
  
}
  
export default Project

export const pageQuery = graphql`
  query BlogPostQuery($id: String) {
		site {
			siteMetadata {
				title
				siteUrl
			}
		}
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
				title,
				date(formatString: "MMMM Do, YYYY"),
				path
      }
    }
  }
`